import languageItems from "./languageItems.js";

const LanguageUtil = {
  languageItems: languageItems,
  defaultLanguage: function () {
    let navigatorLanguage = (
      navigator.language || navigator.browserLanguage
    ).toLowerCase();
    var matchLanguage = "en";
    let languageKeys = Object.keys(this.languageItems);
    let languageLen = languageKeys.length;
    for (let i = 0; i < languageLen; i++) {
      let messagesKey = languageKeys[i];
      if (navigatorLanguage.includes(messagesKey)) {
        matchLanguage = messagesKey;
        break;
      }
    }
    return matchLanguage;
  },
  defaultLanguageText: function () {
    return this.languageItems[this.defaultLanguage()] || "English";
  },
};

export default LanguageUtil;
