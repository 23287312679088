// TODO: 先解决报错，后期再处理cookie禁用的存储问题
const diyLocalStorage = {
  setItem(key, value) {
    if (!key) return;
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("storage.js------", e);
    }
  },
  getItem(key) {
    let value;
    if (!key) return value;
    try {
      value = localStorage.getItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("storage.js------", e);
    }
    return value;
  },
  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("storage.js------", e);
    }
  },
};

const diySessionStorage = {
  setItem(key, value) {
    if (!key) return;
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("storage.js------", e);
    }
  },
  getItem(key) {
    let value;
    if (!key) return value;
    try {
      value = sessionStorage.getItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("storage.js------", e);
    }
    return value;
  },
  removeItem(key) {
    if (!key) return;
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("storage.js------", e);
    }
  },
};

function clearStorage() {
  removeStorageToken();
  diyLocalStorage.removeItem("userid");
  diyLocalStorage.removeItem("isInit");
  diyLocalStorage.removeItem("billAddressStatus");
  diyLocalStorage.removeItem("webTitle");
  diyLocalStorage.removeItem("username");
  diyLocalStorage.removeItem("email");
  diyLocalStorage.removeItem("subscribed");
  diyLocalStorage.removeItem("link_count");
  diyLocalStorage.removeItem("icon_count");
  diyLocalStorage.removeItem("last_copy_time");
  diyLocalStorage.removeItem("xGaClientId");
}

function setStorageToken(token, $cookies) {
  localStorage?.setItem("token", token || '');
  // set cookie for landing page
  $cookies?.set("token", token || '');
}
function removeStorageToken($cookies) {
  localStorage?.removeItem("token");
  // remove cookie for landing page
  $cookies?.remove("token");
}
export {
  diyLocalStorage,
  diySessionStorage,
  clearStorage,
  setStorageToken,
  removeStorageToken,
};
