import { diyLocalStorage } from "./storage";
export function getGtagId() {
  let xCrossGaClientId =
    window._xCrossGaClientId ||
    diyLocalStorage.getItem("xCrossGaClientId") ||
    "";
  if (!xCrossGaClientId && window.gtag) {
    window.gtag("get", "G-EP5LSFL11G", "client_id", (clientID) => {
      window._xCrossGaClientId = clientID;
      diyLocalStorage.setItem("xCrossGaClientId", clientID);
      xCrossGaClientId = clientID;
    });
  }
  let xCrossGaSessionId =
    window._xCrossGaSessionId ||
    diyLocalStorage.getItem("xCrossGaSessionId") ||
    "";
  if (!xCrossGaSessionId && window.gtag) {
    window.gtag("get", "G-EP5LSFL11G", "session_id", (sessionID) => {
      window._xCrossGaSessionId = sessionID;
      diyLocalStorage.setItem("xCrossGaSessionId", sessionID);
      xCrossGaSessionId = sessionID;
    });
  }
  let gtagConfig = {};

  if (xCrossGaClientId) {
    gtagConfig["client_id"] = xCrossGaClientId;
  }
  if (xCrossGaSessionId) {
    gtagConfig["session_id"] = xCrossGaSessionId;
  }

  return Object.keys(gtagConfig).length ? gtagConfig : null;
}
function getQueryString(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
}

export function setGtagId() {
  if (!window.gtag) return;
  let client_id = getQueryString("gClientId");
  let session_id = getQueryString("gSessionId");
  let xCrossGaClientId =
    window._xCrossGaClientId ||
    diyLocalStorage.getItem("xCrossGaClientId") ||
    "";
  let xCrossGaSessionId =
    window._xCrossGaSessionId ||
    diyLocalStorage.getItem("xCrossGaSessionId") ||
    "";
  if (
    client_id &&
    (client_id != xCrossGaClientId || session_id != xCrossGaSessionId)
  ) {
    let config = { client_id };
    if (session_id) {
      config["session_id"] = session_id;
      window._xCrossGaSessionId = session_id;
      diyLocalStorage.setItem("xCrossGaSessionId", session_id);
    }
    window.gtag("config", "G-EP5LSFL11G", {
      client_id,
      session_id,
    });
    window._xCrossGaClientId = client_id;
    diyLocalStorage.setItem("xCrossGaClientId", client_id);
  }
}

function updateQueryStringParameter(uri, key, value) {
  if (!value) {
    return uri;
  }
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

export function OpenBioLink(href, target = "_self") {
  let link = href;
  let gtagConfig = getGtagId();
  if (gtagConfig) {
    if (gtagConfig["client_id"]) {
      link = updateQueryStringParameter(
        link,
        "gClientId",
        gtagConfig["client_id"]
      );
    }
    if (gtagConfig["session_id"]) {
      link = updateQueryStringParameter(
        link,
        "gSessionId",
        gtagConfig["session_id"]
      );
    }
  }

  window.open(link, target);
}
