import { diySessionStorage } from "./storage";
import { OpenBioLink } from "./gtagCrossLink";
const utils = {
  // 校验网址
  urlPattern: function (url) {
    return /(http|https):\/\/([\w.]+\/?)\S*/.test(url);
  },
  // 国外网址校验 国外网址可能不是以www大头 可能是vm等 故 不做www校验
  foreignUrlPattern: function (url) {
    return /(http|https):\/\/\S*/.test(url);
  },
  newUrlPattern: function (url) {
    return /(https|http)?:\/\/([\w-]+\.)+[\w-]+(.*)$/.test(url);
  },
  emailPattern: function (email) {
    return /^^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(
      email
    );
  },
  // 数字或两位小数
  numPattern: function (val) {
    return /^\d+(\.\d{0,2})?$/.test(val);
  },
  // 移除左右空格
  trim: function (val) {
    return val.replace(/^\s+|\s+$/gm, "");
  },
  // 获取uuid
  getUuid: function () {
    const s = [];
    const hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    const uuid = s.join("");
    return uuid;
  },

  // 数字格式化 eg. 1000 = 1k
  formatNumber: function (num) {
    let output = "0";
    if (num < 1e3) {
      output = num.toString();
    } else if (num < 1e6) {
      output = Math.floor((num / 1e3) * 10) / 10 + "K";
    } else if (num < 1e9) {
      output = Math.floor((num / 1e6) * 10) / 10 + "M";
    } else {
      output = Math.floor((num / 1e9) * 10) / 10 + "B";
    }
    return output;
  },

  throttle: function (func, delay) {
    let timer = null;
    let startTime = Date.now();
    return function () {
      const curTime = Date.now();
      const remaining = delay - (curTime - startTime);
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      if (remaining <= 0) {
        func.apply(context, args);
        startTime = Date.now();
      } else {
        timer = setTimeout(func, remaining);
      }
    };
  },
  /**
   *
   * @param {*} fn  方法
   * @param {*} delay 事件
   * @description 防抖函数
   * @returns
   */
  debounce(fn, delay) {
    let timer = null; // 借助闭包
    return function (...args) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.call(this, ...args);
      }, delay); // 简化写法
    };
  },
  mobileType(minResolution) {
    const min = minResolution || 768;
    // process.browser &&
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ) ||
      document.body.clientWidth <= min
    ) {
      return "mobile";
    } else {
      return "pc";
    }
  },
  /**
   *
   * @param {*} url 视频url
   * @description 获取视频第一帧
   * @returns
   */
  getVideoBase64(url) {
    return new Promise(function (resolve, reject) {
      let dataURL = "";
      let video = document.createElement("video");
      video.setAttribute("crossOrigin", "anonymous"); // 处理跨域
      video.setAttribute("src", url);
      video.setAttribute("width", 400);
      video.setAttribute("height", 240);
      video.setAttribute("preload", "auto");
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        video.autoplay = true;
      }
      video.addEventListener(
        "loadeddata",
        function () {
          const canvas = document.createElement("canvas");
          const width = video.width; // canvas的尺寸和图片一样
          const height = video.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(video, 0, 0, width, height); // 绘制canvas
          dataURL = canvas.toDataURL("image/jpeg"); // 转换为base64
          video = null;
          if (dataURL) {
            resolve(dataURL);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
          }
        },
        false
      );
      video.src = url;
    });
  },
  getQueryString(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;
  },
  isIos() {
    const UA = navigator.userAgent;
    if (
      UA.match(/iPad/) ||
      UA.match(/iPhone/) ||
      UA.match(/iPod/) ||
      UA.match(/iOS/)
    ) {
      return true;
    } else {
      return false;
    }
  },
  isAndroid() {
    return (
      window.navigator.userAgent.includes("Android") ||
      window.navigator.userAgent.includes("Adr")
    );
  },
  isWx() {
    const UA = navigator.userAgent;
    return UA.toLowerCase().includes("micromessenger");
  },
  isPC() {
    const userAgentInfo = navigator.userAgent;
    const Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },
  isMobile,
  downloadHandle(platform) {
    const downloadMap = {
      android: `https://play.google.com/store/apps/details?id=com.levermore.linkr&referrer=utm_source%3DLinkrWeb%26utm_medium%3DManagerPageMenu%26utm_term%3DDownloadApp`,
      ios: `https://apps.apple.com/app/linkr-powerful-link-in-bio/id1606503443?utm_source=LinkrWeb&utm_medium=ManagerPageMenu&utm_campaign=DownloadApp`,
    };
    window.open(downloadMap[platform]);
  },
  originObj(params) {
    const obj = {};
    for (const i in params) {
      if (params[i]) {
        obj[i] = params[i];
      }
    }
    return obj;
  },
  ArrayBufferToBase64(buffer) {
    // 将ArrayBuffer转为Unicode数组
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (let len = bytes.byteLength, i = 0; i < len; i++) {
      // 转为Unicode编码字符
      binary += String.fromCharCode(bytes[i]);
    }
    // Unicode编码字符转为base64
    return window.btoa(binary);
  },
  isApp() {
    return navigator.userAgent.includes("x-linkr.bio");
  },
  // 数据格式化
  convertNumber(num) {
    const K = 10 ** 3;
    const M = 10 ** 6;
    const B = 10 ** 9;
    const T = 10 ** 12;
    if (num >= T) {
      return Math.floor(num / T) + "T";
    } else if (num >= B) {
      return Math.floor(num / B) + "B";
    } else if (num >= M) {
      return Math.floor(num / M) + "M";
    } else if (num < K * 10 && num >= K) {
      const str = num.toString();
      return str[0] + "," + str[1] + str[2] + str[3];
    } else if (num >= K) {
      return Math.floor(num / K) + "K";
    } else {
      return num;
    }
  },
  isEdge() {
    const ua = window.navigator.userAgent;
    return ua.indexOf("Edge/") || ua.indexOf("Edg/");
  },
  /**
   * 获取月份的日期长度
   * @param {number} year
   * @param {number} month 参数为month+1，如2月传2
   * @returns
   */
  getDayLen(year, month) {
    let dayLen = 30;
    if (month === 2) {
      dayLen =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
    } else {
      dayLen = [1, 3, 5, 7, 8, 10, 12].includes(month) ? 31 : 30;
    }
    return dayLen;
  },
  /**
   * 获取月份缩写
   * @param {number} month 参数为month+1，如2月传2
   * @returns
   */
  getMonthShort(month) {
    const MonthShorts = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return MonthShorts[month - 1] || "";
  },
  /**
   * @description: vue组件引入外部js文件
   * @param {*} target  需要传入的src链接地址
   * @param {*} id  创建script 的id
   * @return {*}
   */
  jsAppend(target, id) {
    // if (process.browser) {
    //   const s = document.createElement("script");
    //   s.type = "text/javascript";
    //   s.src = target;
    //   if (id) {
    //     s.id = id;
    //   }
    //   document.body.appendChild(s);
    // }
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = target;
    if (id) {
      s.id = id;
    }
    document.body.appendChild(s);
  },

  optionChainInTemplate(target, key) {
    const keys = key.split("?.");
    return keys.reduce((a, b) => a?.[b], target);
  },
  openLink: openLink,
  getLandUrl: getLandUrl,
  getPostDetailUrl,
  scrollToPosition: scrollToPosition,
  isSupportImageFormat(format) {
    let isSupport = false;

    // if (!process.browser) {
    //   return false;
    // }

    isSupport = diySessionStorage.getItem(`is_support_${format}`);
    if (isSupport === "true") return true;
    else if (isSupport === "false") return false;
    else {
      isSupport =
        document
          .createElement("canvas")
          .toDataURL(`image/${format}`)
          .indexOf(`data:image/${format}`) === 0;
      diySessionStorage.setItem(`is_support_${format}`, isSupport);
      return isSupport;
    }
  },
  formatDate,

  /**
   * @description: url隐藏http
   * @param {*} url  链接
   * @return {*}
   */
  urlHideHttp(url) {
    return url.replace(/^https?:\/\//i, "");
  },

  goLogin: goLogin,
  delUrlByName: delUrlByName,
};
/**
 * 获取指定元素距离顶部的距离
 * @param {Element} type  register 注册 login 登录
 * @returns
 */
export function goLogin(type) {
  let path = type === "register" ? "signUp" : "login";
  let env =
    window.location.hostname === "dev.linkr.com" ? "test" : "production";
  if (location.hostname === "localhost") {
    env = "development";
  }
  const url = {
    development: `https://dev.linkr.bio/${path}?isDev=true&redirectUrl=${encodeURIComponent(
      location.href
    )}&source=com&origin=${location.origin}`,
    test: `https://dev.linkr.bio/${path}?redirectUrl=${encodeURIComponent(
      location.href
    )}&source=com&origin=${location.origin}`,
    production: `https://linkr.bio/${path}?redirectUrl=${encodeURIComponent(
      location.href
    )}&source=com&origin=${location.origin}`,
  };
  // location.href = url[env];
  OpenBioLink(url[env]);
}

/**
 * 删除url上指定参数
 * @param {Element} name
 * @returns
 */
export function delUrlByName(name) {
  let loca = window.location;
  let baseUrl = loca.origin + loca.pathname;
  let query = loca.search.split("?")[1];
  if (!query) {
    return loca;
  }
  if (query.indexOf(name) > -1) {
    let obj = {};
    let arr = query.indexOf("&") > -1 ? query.split("&") : [query]; //根据&分割，这里的service是unicode编码过得，不存在内部出现&的情况（会被转义成%）
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split("=");
      obj[arr[i][0]] = arr[i][1];
    }
    delete obj[name];
    let str = JSON.stringify(obj) === "{}" ? "" : "?";
    let url =
      baseUrl +
      str +
      JSON.stringify(obj)
        .replace(/[\"\{\}]/g, "")
        .replace(/\:/g, "=")
        .replace(/\,/g, "&");
    return url;
  }
}
export function delUrlByNames(names) {
  let loca = window.location;
  let baseUrl = loca.origin + loca.pathname;
  let query = loca.search.split("?")[1];
  let namesLen = names && names.length ? names.length : 0;
  if (!query || !namesLen) {
    return loca;
  }

  let obj = {};
  let arr = query.indexOf("&") > -1 ? query.split("&") : [query]; //根据&分割，这里的service是unicode编码过得，不存在内部出现&的情况（会被转义成%）
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].split("=");
    obj[arr[i][0]] = arr[i][1];
  }
  for (let j = 0; j < namesLen; j++) {
    if (query.indexOf(names[j]) > -1) {
      delete obj[names[j]];
    }
  }
  let str = JSON.stringify(obj) === "{}" ? "" : "?";
  let url =
    baseUrl +
    str +
    JSON.stringify(obj)
      .replace(/[\"\{\}]/g, "")
      .replace(/\:/g, "=")
      .replace(/\,/g, "&");
  return url;
}
/**
 * 获取指定元素距离顶部的距离
 * @param {Element} element
 * @returns
 */
function getElementTop(element) {
  let actualTop = element.offsetTop;
  let current = element.offsetParent;
  while (current !== null) {
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }
  return actualTop;
}
/**
 * 滚动
 * @param {String} selector 定位元素的class
 * @param {Boolean} isAnimation 是否需要动画
 * @param {Element} scrollParentSelector 滚动容器
 * @returns
 */
export function scroll2middle(
  selector,
  isAnimation = true,
  scrollParentSelector
) {
  const dom = document.querySelector(selector);
  if (!dom) return;
  const domTop = getElementTop(dom);
  const halfWindowInnerHeight = window.innerHeight / 2;
  let scrollParent = null;
  if (scrollParentSelector) {
    scrollParent = document.querySelector(scrollParentSelector);
  }
  if (!scrollParent) scrollParent = document.documentElement;
  if (isAnimation) {
    scrollParent.scrollTo({
      top: Math.abs(domTop - halfWindowInnerHeight),
      behavior: "smooth",
    });
  } else {
    scrollParent.scrollTop = Math.abs(domTop - halfWindowInnerHeight);
  }
}
/**
 *
 * @param {String} selector 定位元素
 * @param {String | Element} selector 定位元素
 * @param {Number} offsetTop 距离顶部偏移量
 * @returns
 */
export function scrollToPosition(selector, scrollParent, offsetTop) {
  const dom = document.querySelector(selector);
  const scrollParentDom =
    typeof scrollParent === "string"
      ? document.querySelector(scrollParent)
      : scrollParent;
  if (!dom || !scrollParentDom) return;
  const domTop = getElementTop(dom);
  scrollParentDom.scrollTo({
    top: Math.abs(domTop - offsetTop),
    behavior: "smooth",
  });
}

export function openLink(url, target = "_blank") {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", target);
  a.setAttribute("id", "camnpr");
  document.body.appendChild(a);
  a.click();
}

export function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag || document.body.clientWidth < 768;
}

export function getLandUrl(userName) {
  const origin = window.location.origin;
  if (origin.startsWith("https")) {
    return `${origin}/${userName}`;
  } else {
    return `${origin}/landing/${userName}`;
  }
}

export function getPostDetailUrl(landUrl, postId) {
  return `${landUrl}/p/${postId}`;
}

export function formatDate(time) {
  let dateTime;
  if (typeof time === "number") {
    const timestamp = String(time).length === 10 ? time * 1000 : time;
    dateTime = new Date(timestamp);
  } else if (typeof time === "string") {
    dateTime = new Date(time);
  }
  const strArr = dateTime.toDateString().split(" ");
  return `${strArr[1]} ${strArr[2]}, ${strArr[3]}`;
}

export function deepClone(obj) {
  // 定义一个变量 并判断是数组还是对象
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object" && obj != null) {
    // 判断obj存在并且是对象类型的时候 因为null也是object类型，所以要单独做判断
    for (let key in obj) {
      // 循环对象类型的obj
      // obj.hasOwnProperty(key);
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // 判断obj中是否存在key属性
        if (obj[key] && typeof obj[key] === "object") {
          // 判断如果obj[key]存在并且obj[key]是对象类型的时候应该深拷贝，即在堆内存中开辟新的内存
          objClone[key] = deepClone(obj[key]);
        } else {
          // 否则就是浅复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

export function download(url, fileName) {
  if (url) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    a.target = "_blank";
    console.log(a);
    a.click();
  }
}

export function formatNumberDot(num) {
  let numStr = (num || 0) + "";
  let countReverse = numStr.split("").reverse();
  let countArr = [];
  let len = countReverse.length;
  let countArrLen = len % 3 === 0 ? parseInt(len / 3) : parseInt(len / 3) + 1;
  // 补充中间逗号长度
  countArrLen += countArrLen;
  for (let i = 0; i < len; i++) {
    let num = +countReverse[i];
    if (i % 3 === 0) {
      countArrLen -= 2;
      countArr[countArrLen] = [num];
      if (countArrLen - 1 > 0) {
        // -1 标记为逗号
        countArr[countArrLen - 1] = ",";
      }
    } else {
      countArr[countArrLen].unshift(num);
    }
  }
  countArr = countArr.flat();
  return countArr.join("");
}

export default utils;
