// import Utils from "@/utils/utils"
const applinkr = {
  jsbridge(params) {
    // 客户端方法交互 js调用native
    // const isApp = Utils.getQueryString("from") === "app" || isInit
    let param = JSON.stringify(params);
    console.log(params, "---sendData---");
    window.appLinkr &&
      window.appLinkr.postMessage &&
      window.appLinkr.postMessage(param);
  },
  pageDone() {
    // 页面渲染完成时
    this.jsbridge(
      {
        methods: "native_render_done",
      },
      true
    );
  },
  pageEditStart() {
    // 通知进入编辑状态
    this.jsbridge({
      methods: "native_edit_in",
    });
  },
  pageEditDone() {
    // 通知离开编辑状态 预留
    this.jsbridge({
      methods: "native_edit_exit",
    });
  },
  pageFollowEdit(dataOptions) {
    // native_follow_edit
    this.jsbridge({
      methods: "native_follow_edit",
      data: dataOptions,
    });
  },
  pageShareEdit(dataOptions) {
    // native_share_edit
    this.jsbridge({
      methods: "native_share_edit",
      data: dataOptions,
    });
  },
  profileEdit(data) {
    // 开启编辑profile模式
    // "title": "profile",
    // "index": 0,
    // "data": {
    // profilePic: "",
    // verifiedBadge: 0
    // }
    this.jsbridge({
      methods: "native_profile_edit",
      index: 0,
      data,
    });
  },
  profileAvatorEdit(data) {
    // 开启头像编辑弹窗   预留
    this.jsbridge({
      methods: "native_profile_avatar_edit",
      data,
    });
  },
  profileTitleEdit(data) {
    // profile 中 title 编辑弹窗 预留
    this.jsbridge({
      methods: "native_profile_title_edit",
      data,
    });
  },
  profileBioEdit(data) {
    this.jsbridge({
      // profile 中bio 编辑弹窗 预留
      methods: "native_profile_bio_edit",
      data,
    });
  },
  iconEdit(data) {
    // 开启单个按钮编辑
    this.jsbridge({
      methods: "native_icon_edit",
      data,
    });
  },
  iconSort(data) {
    // 排序单个按钮后callback
    this.jsbridge({
      methods: "native_icon_sort",
      data,
    });
  },
  postEdit() {
    // 显示隐藏post
    this.jsbridge({
      methods: "native_post_tab_edit",
    });
  },
  /**
   * @description:  tiktob  twitter facebook
   * @param {*} data
   * @return {*}
   */
  moduleEditNative(data) {
    this.jsbridge({
      methods: "native_module_edit",
      data,
    });
  },
  logoEdit(data) {
    // 开启logo信息
    this.jsbridge({
      methods: "native_logo_edit",
      data,
    });
  },
  moduleSort(data) {
    //排序可拖拽module后callback
    this.jsbridge({
      methods: "native_module_sort",
      data,
    });
  },
  linkAdd(data) {
    this.jsbridge({
      methods: "native_module_add",
      data: {
        title: "link",
        ...data,
      },
    });
  },
  styleChange(data) {
    this.jsbridge({
      methods: "native_style_edit",
      data: data,
    });
  },
  // order 通知
  jumpNative(data) {
    // 跳转指定页面
    this.jsbridge({
      methods: "jump_to_native",
      data,
    });
  },
  popCurrent() {
    // 回到上个页面
    this.jsbridge({
      methods: "pop_current_native",
    });
  },
  /**
   * @description: drag icon 点击
   */
  dragIconNative() {
    this.jsbridge({
      methods: "native_drag_icon",
    });
  },
  /**
   * @description: 跳转路由
   * @params to 要跳转的页面
   * @params from 从跳转的页面
   */
  jumpPage(data) {
    this.jsbridge({
      methods: "jump_page",
      data,
    });
  },
};

export default applinkr;
