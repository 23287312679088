import Utils from "@/utils/utils";
import AOS from "aos";
import { mapActions } from "vuex";
import "aos/dist/aos.css";
import { loadLanguageAsync } from "@/lang/i18n";
import languageItems from "@/lang/languageItems.js";
import { diyLocalStorage } from "@/utils/storage";
import LanguageUtils from "@/lang/language_util";
export default {
  data() {
    return {
      mobileType: ""
    };
  },
  mixins: [require("./router/ssr_mixin")],
  computed: {
    isMobile() {
      return this.mobileType === "mobile";
    }
  },
  created() {
    this.resizeMobileType();
    window.addEventListener("resize", this.resizeMobileType, false);
  },
  beforeMount() {
    // 处理多语言问题
    const locale = diyLocalStorage.getItem("locale-language") || LanguageUtils.defaultLanguage();
    let currentLang = this.$route.path.substring(1) || this.$route.query.lang || locale;
    if (currentLang && currentLang in languageItems) {
      loadLanguageAsync(this.$i18n, currentLang);
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeMobileType, false);
  },
  mounted() {
    AOS.init({
      offset: this.isMobile ? 30 : 100,
      duration: 1000
    });
    this.initUserInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    resizeMobileType() {
      this.mobileType = Utils.mobileType();
    },
    initUserInfo() {
      let token = window.localStorage && window.localStorage.getItem("token");
      if (token) {
        this.getUserInfo();
      }
    }
  }
};