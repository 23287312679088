const languageItems = {
  pt: "Português", // 葡萄牙语语言包
  en: "English", // 英文语言包
  id: "Bahasa Indonesia", // 印度尼西亚语语言包
  es: "Español", // 西班牙语语言包
  fr: "Français", // 法语语言包
  de: "Deutsch", // 德语语言包
  ru: "Русский язык", // 俄语语言包
  ja: "日本語", // 日本语
};
// remove zh

export default languageItems;
