import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { diyLocalStorage } from "@/utils/storage";
import languageItems from "./languageItems";

export const SUPPORT_LOCALES = [
  "en",
  "de",
  "es",
  "fr",
  "id",
  "ja",
  "pt",
  "ru",
];
// remove zh
// 2. 创建一个 loadedLanguages 数组，它将跟踪我们加载的语言
const loadedLanguages = [];

export function setupI18n(options = { locale: "en" }) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  console.log("setI18nLanguage -->", i18n);
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.locale = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html").setAttribute("lang", locale);
  diyLocalStorage.setItem("locale-language", locale);
  diyLocalStorage.setItem("locale-language-text", languageItems[locale]);

  //set cookie for landing page
  const docCookies = require("@/utils/cookies");
  docCookies.setItem("locale-language", locale, Infinity, "/", ".linkr.com");
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./lang_${locale}.js`
  );

  console.log(
    "loadLocaleMessages",
    i18n,
    "locale->",
    locale,
    "message->",
    messages.default
  );

  loadedLanguages.push(locale);

  // set locale and locale message
  if (i18n.mode === "legacy") {
    i18n.global.setLocaleMessage(locale, messages.default);
  } else {
    i18n.setLocaleMessage(locale, messages.default);
  }

  return nextTick();
}

export async function loadLanguageAsync(i18n, lang) {
 if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      await loadLocaleMessages(i18n, lang);
      setI18nLanguage(i18n, lang);
    }
    return Promise.resolve(setI18nLanguage(i18n, lang));
 }
 return Promise.resolve(lang);
}
