module.exports = {
  computed: {
    inSSR() {
      return window.__PRERENDER_INJECTED;
    },
    doneSSR() {
      return window.__LINKR_PRERENDERED;
    },
  },
  methods: {
    DoSSR(asyncData) {
      this.ssrDataPromise = new Promise((resolve) => {
        //在浏览器中执行并且已经进行过预渲染，直接使用预渲染的数据
        let renderData = {};
        if (window.__LINKR_PRERENDERED) {
          renderData = JSON.parse(
            decodeURIComponent(window.__LINKR_PRERENDERED)
          );
        }
        if (renderData.dataok) {
          resolve(renderData);
        } else {
          asyncData().then((data) => {
            //在SSR环境中，需要保存预渲染数据到最终的html中
            if (window.__PRERENDER_INJECTED) {
              const script = document.createElement("script");

              script.innerHTML = `window.__LINKR_PRERENDERED = "${encodeURIComponent(
                JSON.stringify(data)
              )}";`;
              document.head.appendChild(script);
            }
            resolve(data);
          });
        }
      });
      return this.ssrDataPromise;
    },
  },
  mounted() {
    //让子组件的 mounted 函数先执行，如果有需要，子组件会调用DoSSR函数从而给ssrDataPromise赋值
    setTimeout(async () => {
      if (this.ssrDataPromise) {
        await this.ssrDataPromise;
        //等待子组件mounted函数其它的初始化结果
        setTimeout(() => {
          document.dispatchEvent(new Event("render-event"));
        }, 10);
      } else {
        document.dispatchEvent(new Event("render-event"));
      }
    }, 10);
  },
};
