const Utils = require("@/utils/utils");
const axios = require("axios");
const { v4: uuidv4 } = require("uuid");
const md5 = require("md5");
const keyJson = require("./key.json");
const appver = require("../ver.json");
const keyConfig = require("@/config/api.json");
const {
  diyLocalStorage,
  diySessionStorage,
  setStorageToken,
} = require("@/utils/storage");
const language_utils = require("@/lang/language_util.js");
const env = process.env.VUE_APP_CONFIG;
const signkey = env ? keyConfig[env].signKey : keyJson.signKey;
const signver = env ? keyConfig[env].signVersion : keyJson.signVersion;
const BASR_URL = env ? keyConfig[env].api : keyJson.apiEntry;

const entry = axios.create({
  baseURL: BASR_URL + "/", //开发环境
  timeout: 10000,
  headers: {
    "X-VERSION": signver,
    "X-APP-VER": appver.VERSION,
    "X-APP-BRANCH": appver.BRANCH,
  },
});
function olaakNonceSig() {
  const nonce = uuidv4();
  const sig = md5(nonce + signkey);
  return { nonce, sig };
}

function judgeClient() {
  let client = "web";
  var UA = window.navigator.userAgent;
  if (UA.indexOf("Android") > -1 || UA.indexOf("Adr") > -1) {
    client = "android";
  } else if (
    UA.match(/iPad/) ||
    UA.match(/iPhone/) ||
    UA.match(/iPod/) ||
    UA.match(/iOS/)
  ) {
    client = "ios";
  }
  return client;
}

const Jsbridge = require("@/utils/jsbridge");
/**
 * 统一请求接口
 * @param {*} path 请求的接口路径
 * @param {*} body 请求的参数，参数格式以json对象形式
 * @param {*} flag  true：不需要token， false：默认需要token
 * @param {*} pass  true：不需要登出处理， false：默认需要登出处理
 * @param {*} header Object, 默认不传，作为接口调用中headers的追加
 */
module.exports = async (path, body, flag, pass, header) => {
  var channelId = diySessionStorage.getItem("channelId") || "";
  var linkrHref = document.location.href;
  if (!window._platform) {
    window._platform = judgeClient();
  }
  var platform = window._platform || "web";
  if (!diySessionStorage.getItem("linkrReferrer")) {
    diySessionStorage.setItem("linkrReferrer", document.referrer);
  }

  // var linkrReferrer = diySessionStorage.getItem("linkrReferrer")

  let headers =
    !header || Object.prototype.toString.call(header) !== "[object Object]"
      ? {}
      : header;
  let store;
  if (!flag) {
    store = require("../store").default;
    var token = diyLocalStorage.getItem("token") || store.state.user.token;
    //如果需要token
    if (!token) {
      console.log("没有token========", token);
      //location.href = '/login'
    } else {
      headers = Object.assign(headers, { "X-TOKEN": token });
      //await virtualLogin();
    }
  }

  const { nonce, sig } = olaakNonceSig();
  const docCookies = require("../utils/cookies");
  const linkrid = docCookies.getItem("linkr-client-id");
  const createat = docCookies.getItem("linkr-client-createat");
  const xLinkrDispatch = docCookies.getItem("x-linkr-dispatch");

  //add google ga client
  let xGaClientId =
    window._xGaClientId || diyLocalStorage.getItem("xGaClientId") || "";
  if (!xGaClientId && window.gtag) {
    window.gtag("get", "G-8Y5TSB88HK", "client_id", (clientID) => {
      window._xGaClientId = clientID;
      diyLocalStorage.setItem("xGaClientId", clientID);
      xGaClientId = clientID;
    });
  }
  const lang =
    diyLocalStorage.getItem("locale-language") ||
    language_utils.default.defaultLanguage();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  headers = Object.assign(headers, {
    "X-NONCE": nonce,
    "X-SIGNATURE": sig,
    "X-CHANNEL-ID": channelId,
    "X-LINKR-DISPATCH": xLinkrDispatch,
    "X-LINKR-HREF": linkrHref,
    "X-LINKR-REFERRER": document.referrer,
    "X-LINKR-CLIENT-ID": linkrid,
    "X-LINKR-CLIENT-CREATEAT": createat,
    "X-CLIENT-ID": xGaClientId,
    "X-PLATFORM": platform,
    language: lang,
    timezone,
  });
  const rsp = await entry.post(path, body, {
    headers: headers,
  });
  if (!pass) {
    if (rsp.data.code == 401 || rsp.data.code == 402) {
      //token过期
      setStorageToken("");
      diyLocalStorage.setItem("userid", "");
      store.commit("user/setToken", undefined);
      store.commit("user/setIsInit", 0);
      let isApp = Utils.default.isApp();
      if (!isApp) {
        // let location = window.location;
        // let from = location.pathname + location.search;
        // location.replace(from ? `/login?fromUrl=${from}` : "/login");
      } else {
        Jsbridge.default.jumpNative({
          route: "login",
        });
      }
    } else {
      return rsp.data;
    }
  } else {
    return rsp.data;
  }
};

/**
 * @description:
 * @param {*} path
 * @param {*} body
 * @param {*} flag
 * @param {*} uploadProgress  上传下载百分百  如图片上传 视频上传 下载等百分比 用于显示进度条
 * @param {*} cancelTokenSource   axios官方提供的中止请求的方法，详情请见：https://axios-http.com/docs/cancellation
 * @return {*}
 */
module.exports.postForm = async (
  path,
  body,
  flag,
  uploadProgress = () => {},
  cancelTokenSource,
  timeout = 60000
) => {
  var channelId = diySessionStorage.getItem("channelId") || "";
  var linkrHref = document.location.href;
  if (!window._platform) {
    window._platform = judgeClient();
  }
  var platform = window._platform || "web";
  if (!diySessionStorage.getItem("linkrReferrer")) {
    diySessionStorage.setItem("linkrReferrer", document.referrer);
  }
  var linkrReferrer = diySessionStorage.getItem("linkrReferrer");
  let formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }

  let headers = {};
  let store;
  if (!flag) {
    store = require("../store").default;
    var token = diyLocalStorage.getItem("token") || store.state.user.token;
    //如果需要token
    if (!token) {
      // location.href = "/login";
    } else {
      headers = Object.assign(headers, { "X-TOKEN": token });
      //await virtualLogin();
    }
  }

  const { nonce, sig } = olaakNonceSig();

  //add google ga client
  let xGaClientId =
    window._xGaClientId || diyLocalStorage.getItem("xGaClientId") || "";
  if (!xGaClientId && window.gtag) {
    window.gtag("get", "G-8Y5TSB88HK", "client_id", (clientID) => {
      window._xGaClientId = clientID;
      diyLocalStorage.setItem("xGaClientId", clientID);
      xGaClientId = clientID;
    });
  }

  const lang =
    diyLocalStorage.getItem("locale-language") ||
    language_utils.default.defaultLanguage();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  headers = Object.assign(headers, {
    "X-NONCE": nonce,
    "X-SIGNATURE": sig,
    "X-CHANNEL-ID": channelId,
    "X-LINKR-HREF": linkrHref,
    "X-LINKR-REFERRER": linkrReferrer,
    "X-CLIENT-ID": xGaClientId,
    "X-PLATFORM": platform,
    language: lang,
    timezone,
  });
  const config = {
    timeout,
    headers: headers,
    onUploadProgress: (progress) => {
      uploadProgress(progress);
    },
  };
  if (cancelTokenSource) {
    config.cancelToken = cancelTokenSource.token;
  }
  const rsp = await entry.post(path, formData, config);
  if (rsp.data.code == 401 || rsp.data.code == 402) {
    //token过期
    setStorageToken("");
    diyLocalStorage.setItem("userid", "");
    if (store) {
      store.commit("user/setToken", undefined);
      store.commit("user/setIsInit", 0);
    }
    window && (window.__linkr_userid = "");
    let isApp = Utils.default.isApp();
    if (!isApp) {
      // let location = window.location;
      // let from = location.pathname + location.search;
      // location.replace(from ? `/login?fromUrl=${from}` : "/login");
    } else {
      Jsbridge.default.jumpNative({
        route: "login",
      });
    }
  } else {
    return rsp.data;
  }
};

/**
 * 统一请求接口
 * @param {*} path 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} flag  true：不需要token， false：默认需要token
 */
module.exports.get = async (path, params, flag) => {
  var channelId = diySessionStorage.getItem("channelId") || "";
  var linkrHref = document.location.href;
  if (!window._platform) {
    window._platform = judgeClient();
  }
  var platform = window._platform || "web";
  if (!diySessionStorage.getItem("linkrReferrer")) {
    diySessionStorage.setItem("linkrReferrer", document.referrer);
  }
  let headers = {};
  let store;
  if (!flag) {
    store = require("../store").default;
    var token = diyLocalStorage.getItem("token") || store.state.user.token;
    //如果需要token
    if (!token) {
      console.log("没有token========", token);
      //location.href = '/login'
    } else {
      headers = Object.assign(headers, { "X-TOKEN": token });
    }
  }

  const { nonce, sig } = olaakNonceSig();
  const docCookies = require("../utils/cookies");
  const linkrid = docCookies.getItem("linkr-client-id");
  const createat = docCookies.getItem("linkr-client-createat");
  const xLinkrDispatch = docCookies.getItem("x-linkr-dispatch");

  //add google ga client
  let xGaClientId =
    window._xGaClientId || diyLocalStorage.getItem("xGaClientId") || "";
  if (!xGaClientId && window.gtag) {
    window.gtag("get", "G-8Y5TSB88HK", "client_id", (clientID) => {
      window._xGaClientId = clientID;
      diyLocalStorage.setItem("xGaClientId", clientID);
      xGaClientId = clientID;
    });
  }
  const lang =
    diyLocalStorage.getItem("locale-language") ||
    language_utils.default.defaultLanguage();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  headers = Object.assign(headers, {
    "X-NONCE": nonce,
    "X-SIGNATURE": sig,
    "X-CHANNEL-ID": channelId,
    "X-LINKR-DISPATCH": xLinkrDispatch,
    "X-LINKR-HREF": linkrHref,
    "X-LINKR-REFERRER": document.referrer,
    "X-LINKR-CLIENT-ID": linkrid,
    "X-LINKR-CLIENT-CREATEAT": createat,
    "X-CLIENT-ID": xGaClientId,
    "X-PLATFORM": platform,
    language: lang,
    timezone,
  });

  // 处理配置的url
  let base = keyJson.apiEntry;

  let url = `${base}/${path}`;
  const rsp = await axios.get(url, {
    headers: headers,
    params: params,
  });
  if (rsp.data.code == 401 || rsp.data.code == 402) {
    //token过期
    setStorageToken("");
    diyLocalStorage.setItem("userid", "");
    if (store) {
      store.commit("user/setToken", undefined);
      store.commit("user/setIsInit", 0);
    }
    // let location = window.location;
    // let from = location.pathname + location.search;
    // location.replace(from ? `/login?fromUrl=${from}` : "/login");
  } else {
    return rsp.data;
  }
};
