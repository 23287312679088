import { getUserInfo, logout } from "@/api/user";
const state = () => ({
  isLogin: false,
  userInfo: {},
  token: undefined,
  isInit: 0,
});

const mutations = {
  setUserInfo(state, payload) {
    state.userInfo = payload;
  },
  setLoginStatus(state, payload) {
    state.isLogin = payload;
  },
  setToken(state, token) {
    state.token = token;
  },
  setIsInit(state, isInit) {
    state.isInit = isInit;
  },
};

const actions = {
  async getUserInfo({ commit }) {
    const { data, code } = await getUserInfo().then((res) => {
      return res || {};
    });
    if (code === 0) {
      commit("setUserInfo", data);
      commit("setLoginStatus", true);
    }
  },
  async logoutUser({ commit }) {
    await logout();
    localStorage.removeItem("token");
    localStorage.removeItem("xCrossGaClientId");
    localStorage.removeItem("xCrossGaSessionId");
    location.reload();
    commit("setLoginStatus", false);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
