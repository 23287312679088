import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import { getToken } from "@/api/user";
import { delUrlByNames } from "@/utils/utils";
import { setGtagId } from "@/utils/gtagCrossLink";

export function setupRouter() {
  const IndexChildren = [
    {
      path: "/",
      alias: ["/de", "/es", "/fr", "/id", "/ja", "/pt", "/ru"],
      name: "Index",
      meta: {
        showContact: true, // 显示客服入口
      },
      component: () =>
        import(
          /* webpackChunkName: "linkr.home" */ "../views/home-wrapper/index.vue"
        ),
    },
    {
      path: "/en",
      redirect: { path: "/", query: { lang: "en" } },
    },
    {
      path: "/best-linkinbio-page-example-templates-for-all-creators",
      name: "TemplateInspo",
      meta: {
        showContact: true, // 显示客服入口
        title:
          "20 Best link in bio page template examples 2022-2023 | linkr.bio",
        content: {
          description:
            "Linkr: we reviewed 1000 link in bio examples of our creators. Here are the top 20 most popular and best-practiced instagram link in bio template that inspire everyone!",
        },
      },
      component: () =>
        import(
          /* webpackChunkName: "linkr.inspo" */ "@/views/home-wrapper/templateInspo.vue"
        ),
    },
    {
      path: "/influencer-marketing-with-linkr",
      name: "Advertise",
      meta: {
        showContact: true, // 显示客服入口
        title:
          "Influencer marketing and adveritse with content creators | linkr.bio",
        content: {
          description:
            "Linkr: marketing with Linkr creators! We connect business brands to the right linkr creators and social influencers.",
        },
      },
      component: () =>
        import(
          /* webpackChunkName: "linkr.advertise" */ "../views/home-wrapper/AdvertiseWrapper.vue"
        ),
    },
  ];

  const routes = [
    {
      path: "/auth",
      name: "auth",
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/auth/authIndex.vue"),
    },
    {
      path: "/",
      meta: {
        showContact: true, // 显示客服入口
      },
      component: () =>
        import(
          /* webpackChunkName: "linkr.indexWrapper" */ "../views/home-wrapper/IndexWrapper.vue"
        ),
      children: IndexChildren,
    },
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to) {
      if (to && to.hash) {
        return {
          el: to.hash,
          behavior: "smooth",
        };
      }
      return { top: 0 };
    },
    routes,
  });

  trackRouter(router);

  router.beforeEach(async (to, from, next) => {
    resetToken(to);

    // 路由中重置gtag config
    if (to.query.gClientId || to.query.gSessionId) {
      setTimeout(() => {
        setGtagId();
      }, 800);
    }
    next();
  });

  return router;
}

async function resetToken(to) {
  let query = to.query;
  let imageToken = query.imageToken;
  let userid = query.uid;

  if (imageToken) {
    const { data } = await getToken({ imageToken });
    localStorage.setItem("token", data.token);
    if (userid) {
      localStorage.setItem("userid", userid);
    }
    location.replace(delUrlByNames(["imageToken", "uid"]));
  }
  let token = query.token;
  if (token) {
    localStorage.setItem("token", token);
    if (userid) {
      localStorage.setItem("userid", userid);
    }
    location.replace(delUrlByNames(["token", "uid"]));
  }
}
