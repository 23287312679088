import { createStore } from "vuex";
import user from "@/store/modules/user";
const store = createStore({
  state() {
    return {};
  },
  modules: {
    user,
  },
});

export default store;
